@import "/src/variable";

* {
  margin: 0;
  padding: 0;
}

.adminMain_Container {
  width: calc(100% - 15rem);
  padding: 1.5rem;
  float: right;
  box-sizing: border-box;
  background-color: $dashboard_bg;
  height: 200vh;
}

.open {
  width: calc(100% - 5rem);
  float: right;
  background-color: $dashboard_bg;
  height: 200vh;
}

.Admin_maincontainer {
  width: 100%;
  height: 100vh;

  .page_heading {
    margin: 0.2rem;

    h3 {
      font-size: 26px;
      color: $dashboard_font;
      font-weight: 600;
    }
    h4 {
      font-size: 20px;
    }
  }
  .top_ranking {
    display: flex;
    justify-content: center;

    .ranking_box {
      height: 12vh;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50%;
      margin-top: 20px;
      box-shadow: none !important;
      border-radius: 8px;

      .project_box {
        width: 60px;
        height: 60px;
        border-radius: 25px;
        background-color: $dashboard_icon_bg;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
          height: 4.5vh;
        }
        .client_img {
          overflow: hidden;
          border-radius: 50px;
        }
      }
      .product_text {
        h3 {
          font-size: 30px;
          font-weight: 600;
          color: $dashboard_font;
          text-align: right;
        }
        span {
          font-size: 16px;
          color: $dashboard_font;
        }
      }
    }
  }
  .graph_container {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    height: 48vh;

    .graphone {
      background-color: $dashboard_box;
      width: 50%;
      padding: 30px;
      border-radius: 8px;
    }
    .graphtwo {
      width: 50%;
      margin-left: 25px;
      padding: 30px;
      background-color: $dashboard_box;
      border-radius: 8px;
    }

    .css-1qdzy9k-MuiBarElement-root {
      fill: red !important;
    }
  }
  .Earn_container {
    display: flex;
    justify-content: center;

    .employe_box {
      height: 20vh;
      width: 100%;
      margin-top: 50px;
      box-shadow: none;
      border-radius: 8px;

      .employe_text {
        display: flex;

        gap: 45%;
        h3 {
          width: 50%;
          font-size: 18px;
          color: $dashboard_font;
          font-weight: 500;
        }
        span {
          font-size: 18px;
          color: $completed_line;
        }
      }
      h2 {
        width: 27%;
        font-size: 2rem;
        font-weight: 500;
        color: $dashboard_font;
        line-height: 4rem;
      }
      .MuiLinearProgress-bar {
        background-color: unset !important;
      }
      .css-5xe99f-MuiLinearProgress-bar1 {
        background-color: unset !important;
      }
      .progress {
        h3 {
          font-size: 18px;
          line-height: 3rem;
          color: $dashboard_font;
          font-weight: 500;

          span {
            opacity: 0.8;
            font-weight: 500;
          }
        }
      }
    }
  }

  .Statistics {
    display: flex;
    justify-content: center;
    height: 55vh;
    margin-top: 50px;

    .Statistics_box {
      height: 50vh;
      box-shadow: none !important;
      border-radius: 8px;

      h1 {
        color: $dashboard_font;
        font-size: 22px;
        font-weight: 600;
        height: 6vh;
      }

      .status {
        height: 9vh;
        .Statistics_text {
          display: flex;

          margin-left: 20px;

          p {
            color: $dashboard_font;
            font-size: 14px;
            width: 85%;
          }
          strong {
            font-size: 14px;
            font-weight: 700;
            color: $dashboard_font;
          }
        }
        .Statistics_progress {
          margin-left: 20px;
          margin-top: 10px;
        }
      }
      .task_rank {
        display: flex;
        justify-content: center;
        height: 10vh;
        gap: 30%;
        .total_rank {
          text-align: center;
          h3 {
            font-size: 14px;
            color: $dashboard_font;
            opacity: 0.8;
          }
          h4 {
            color: $dashboard_font;
            font-size: 1.7rem;
            font-weight: 600;
          }
        }
      }
      .progress_layer {
        width: 100%;
        height: 7vh;
        border-radius: 20px;
        display: flex;

        .Completed {
          width: 30%;
          height: 3vh;
          background-color: $completed_task;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          color: $headerfont_color;
          text-align: center;
          font-size: 16px;
          align-content: center;
        }
        .inprogress {
          width: 22%;
          height: 3vh;
          background-color: $inprogress_task;
          color: $headerfont_color;
          text-align: center;
          font-size: 16px;
          align-content: center;
        }
        .onhold {
          width: 22%;
          height: 3vh;
          background-color: $hold_task;
          color: $headerfont_color;
          text-align: center;
          font-size: 16px;
          align-content: center;
        }
        .pending {
          width: 21%;
          height: 3vh;
          background-color: $pending_task;
          color: $headerfont_color;
          text-align: center;
          font-size: 16px;
          align-content: center;
        }
        .review {
          width: 10%;
          height: 3vh;
          background-color: $review_task;
          color: $headerfont_color;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          text-align: center;
          font-size: 16px;
          align-content: center;
        }
      }
      .task_text {
        height: 5vh;
        h3 {
          font-size: 20px;
          color: $dashboard_font;
          font-weight: 400;

          .float_right {
            float: right;
          }
        }
      }
      .absent_person {
        display: flex;
        margin-left: 25px;
        gap: 20px;
        img {
          width: 10%;
          height: 5vh;
        }
        h3 {
          font-size: 14px;
          color: $dashboard_font;
          align-content: center;
        }
      }
      .absent_date {
        margin: 20px;
        height: 10vh;
        h3 {
          color: $dashboard_font;
        }
        span {
          float: right;
          background-color: #f211361f;
          color: $pending_task;
          font-weight: 500;
          border-radius: 5px;
        }
        .absent_approved {
          color: $completed_line;
          background-color: #0fb76b1f;
        }
        p {
          opacity: 1;
          font-weight: 500;
          font-size: 16px;
        }
      }
      .absent_loadbtn {
        width: 100%;
        display: flex;
        justify-content: center;
        color: $dashboard_font;
        font-size: 16px;
        text-transform: capitalize;
      }
    }
  }
  .bill_container {
    display: flex;
    justify-content: center;

    .invoice_text {
      height: 30vh;

      h1 {
        color: $dashboard_font;
        font-size: 22px;
        font-weight: 600;
        line-height: 4vh;
      }
      .invoice_head {
        display: flex;
        width: 100%;

        .head_text {
          width: 100%;
          h3 {
            color: $dashboard_font;
            font-size: 18px;
            line-height: 8vh;

            img {
              width: 15%;
              height: 2vh;
            }
          }
          p {
            color: $invoice_data_color;
            font-size: 15px;
            font-weight: 500;
            line-height: 4vh;
            width: 100%;
          }
        }
      }
    }
  }
}
