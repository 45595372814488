@import "/src/variable";

.Shedule_Container {
    width: calc(100% - 15rem);
    padding: 1.5rem;
    float: right;
    box-sizing: border-box;
    transition: all 0.2s;
    background-color: $dashboard_bg;
}

.open {
    width: calc(100% - 4rem);
    float: right;
    padding: 1.5rem;
    transition: all 0.2s;
    background-color: $dashboard_bg;

}




@media (max-width: 990px) {
    .open {
        width: 100%;
        padding: 1.5rem;
    }

    .Shedule_Container {
        width: 100%;
    }
}

