@import "/src/variable";

.open {
    width: calc(100% - 5rem);
    float: right;
    padding: 1.5rem;
    transition: all 0.2s;
    background-color: $dashboard_bg;
}

.adminAttendMain_Container {
    width: calc(100% - 15rem);
    padding: 1.5rem;
    float: right;
    transition: all 0.2s;
    background-color: $dashboard_bg;
}

.alHeader {
    margin-bottom: 2rem;
}

.headingSubText {
    display: flex;

    a {
        text-decoration: none;
        color: $dashboard_font;
        font-weight: 500;
    }
}

.avatarDiv {
    display: flex;
    align-items: center;
    width: 10rem;
}

.present {
    color: $active_status;
    cursor: pointer;
}

.absent {
    color: $inactive_status;
}

//DialogBox Css
.dialogContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dgHeading{
    padding: 1rem;
}
.timesheetDflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.dgwidth {
    .MuiDialog-container .MuiPaper-root {
        width: 100% !important;
        max-width: 800px !important;
    }
}


.dgItem {
    padding: 1rem;
    margin: .5rem;
}

.punchInOut {
    border: 1px solid $dialogitem_border;
    background-color: $dialogitem_bg;
    padding: .2rem .5rem;
    border-radius: .3rem;
}

.totalHr {
    border: 8px solid $dialogitem_border;
    height: 8rem;
    width: 8rem;
    margin: 1rem auto;
    border-radius: 500%;
    background-color: $dialogitem_bg;
    position: relative;
}

.breakOverTime {
    border: 1px solid $dialogitem_border;
    background-color: $dialogitem_bg;
    margin-top: 1rem;
    padding: .2rem 2rem;
}

.dgFont {
    color: $dialogitem_subfont;
    font-weight: 500;
}

.dgDarkFont {
    font-weight: 600;
}

.activityTime li {
    padding: 0 0 0 3rem;
    position: relative;

    ::before {
        background: #fff;
        border: 2px solid #ff902f;
        border-radius: 100%;
        content: "";
        height: 10px;
        left: -10px;
        margin: 0 0 0 15px;
        position: absolute;
        top: 0;
        width: .6rem;
        z-index: 2;
    }
    ::after {
        border: 1px solid #e9e9ea;
        bottom: 0;
        content: "";
        left: 10px;
        position: absolute;
        top: 0;
    }
}
.activityItem{
    margin-bottom: 1.5rem;
}
.activityText{
    font-weight: 600;
}
.activityHr{
    color: $dialogitem_subfont;
}

@media(max-width:990px) {
    .open {
        width: 100%;
        padding: 1.5rem;
    }

    .adminAttendMain_Container {
        width: 100%;
    }
    
}

@media (max-width:767px){
    .dialogContent {
        display: block;       
    }
    .dgItem{
        width: 98%;
        padding: .5rem;
        margin-bottom: .5rem;
    }
    .breakOverTime {
        border: 1px solid $dialogitem_border;
        background-color: $dialogitem_bg;
        margin-top: 1rem;
        padding: .2rem .8rem;
    }
    
}
@media(max-width:575px) {
    .headingSubText {
        display: none;
    }

}