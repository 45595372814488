//Login page color variables
$mainbackground_color: #191b1a;
$box_shadow: #d3f85a41;
$boxshadow_filter: #d3f85aa6;
$input_text: black;
$button_bg: #d2f16edc;

// After Login page color variables

//Header
$header_bg1: #ff902f;
$header_bg2: #fc6075;
$headerfont_color: #fff;

//side bar
$sidebar_bg: #212529;
$sidebar_mainfont: #e2e4e6;
$sidebar_subfont: #909294;
$sidebar_submenubg: #373b3e;

//Dashboard
$dashboard_bg: #f7f7f7;
$dashboard_box: #fff;
$dashboard_font: black;
$dashboard_deamcolor: #4d5154;
$dashboard_icon: #ff902f;
$dashboard_icon_bg: #ff9b4433;

//Dialog Box
$dialogitem_bg:#f9f9f9;
$dialogitem_border:#e2e4e6;
$dialogitem_subfont:#7a7c7f;


//Graphs
$graph_Layer1: rgb(255, 155, 68);
$graph_Layer2: rgb(252, 96, 117);

//Progress Lines
$leave_line: #ff902f;
$pending_line: #ffbc34;
$completed_line: #55ce63;
$open_ticket: #fc133d;
$close_ticket: #009efb;

//Task
$completed_task: #9368e9;
$inprogress_task: #ffbc34;
$hold_task: #55ce63;
$pending_task: #fc133d;
$review_task: #009efb;

// Active/inactive  status
$active_status: #55ce63;
$inactive_status: #fc133d;
$panding_status: #009efb;
$new_status: #9368e9;

// Client Progress Line

$client_progress: #25628f;
$client_search_btn: #55ce63;

// Invoice data
$invoice_data_color: #333;
