@import "/src/variable";

.department_Container{
    width: calc(100% - 15rem);
    padding: 1.5rem;
    float: right;
    box-sizing: border-box;
    transition: all 0.2s;
    background-color: $dashboard_bg;
}

.open {
    width: calc(100% - 4rem);
    float: right;
    padding: 1.5rem;
    transition: all 0.2s;
    background-color: $dashboard_bg;

}
.alHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.headingSubText {
    display: flex;

    a {
        text-decoration: none;
        color: $input_text;
        font-weight: 500;
    }
}

.hadingBtn {
    min-width: 10rem;
    border-radius: 3rem;
    height: 2.5rem;
    color: $headerfont_color;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: $dashboard_icon;
    border: 1px solid $dashboard_icon;
}

.dialogBoxBtn {
    padding: .5rem 3rem;
    margin: 1.5rem !important;
    border-radius: 1rem !important;
    color: $dashboard_box !important;
    background-color: $dashboard_icon !important;

    &:hover {
        background-color: $dashboard_icon !important;
        transition: all .2s ease;
    }
}

.tableHeader {
    display: flex;

    p {
        color: $sidebar_subfont;
        font-weight: 600;
    }
}

//dialog
.dialogHeader {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 990px) {
    .open {
        width: 100%;
        padding: 1.5rem;
    }
    .department_Container{
        width: 100% ;  
    }
}
@media(max-width:575px) {
    .headingSubText {
        display: none;
    }
}