@import "/src/variable";
.dealMain_Container {
    width: calc(100% - 15rem);
    padding: 1.5rem;
    float: right;
    box-sizing: border-box;
    transition: all 0.2s;
    background-color: $dashboard_bg;}

.open {
    width: calc(100% - 4rem);
    float: right;
    padding: 1.5rem;
    transition: all 0.2s;
    background-color: $dashboard_bg;

}

.dealHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dealHeader_input {
    display: flex;
    align-items: center;
}

.export {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .2rem;
    color: #4d5154;
    border: 1px solid #e2e4e6;
    padding: .3rem;
    margin-right: 1.5rem;
    margin-bottom: .2rem;

    p {
        margin-bottom: 0.3rem;
    }
}

.date_select {
    display: flex;
    align-items: center;
}

.date_range {
    display: flex;
    align-items: center;
    height: 3.2rem;
    border: 1px solid rgb(208, 202, 202);
    border-radius: .3rem;
}

.viewIcon {
    font-size: 1.2rem;
    padding-top: .5rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    border-radius: 0.4rem;
    margin-right: 0.5rem;
    background-color: $dashboard_bg;
    color: $dashboard_deamcolor;
    border: 1px solid $sidebar_mainfont;

    &:hover {
        background-color: $dashboard_icon;
        transition: all .2s ease;
        color: $dashboard_bg;
    }
}

.dealMainContent {
    display: flex;
    flex-wrap: wrap;
}

.dealStages {
    width: calc(50% - 1rem);
    margin: .5rem;

    .openstate {
        color: $leave_line;
    }

    .won {
        color: $hold_task;
    }

    .lost {
        color: $pending_task;
    }
}

.barGraph {
    width: 100%;
    height: 200px;
}
.dealByStageGraph{
    width: 99%;
    height: 400px;
}
.dealConent {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.allDealHeader {
    font-weight: 550;
}

.paperSelect {
    display: flex;
}

.calenderDay {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    list-style-type: none;

    li {
        &:hover {
            background-color:$sidebar_mainfont;
        }
    }
}

.calenderFooter {
    display: flex;
    justify-content: end;
    align-items: center;
}

@media (max-width: 990px) {
    .open {
        width: 100%;
        padding: .5rem;

        .dealHeader_input {
            .fullscreenIcon {
                display: none;
            }
        }
    }
    .dealMain_Container{
        width: 100%;
    }

    .dealMain_Container {
        padding: .5rem;
        display: block;
        width: 100%;
    }

    .fullscreenIcon {
        display: none;
    }

    .calenderFooter {
        flex-direction: column;
        align-items: center;
    }

    .calenderFooter p {
        margin-bottom: 8px;
    }

    .calenderFooter button {
        width: 100%;
        margin-bottom: 8px;
    }

    .calenderFooter button:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .dealHeader {
        display: block;
    }

    .dealMainContent {
        display: block;
    }

    .dealSub_Container .dealHeader {
        display: block;
    }

    .dealStages {
        width: 100%;
        margin: .5rem 0;
    }
}

@media(max-width:575px) {
    .dealSub_Container {
        padding: .5rem;
    }

    .dealHeader {
        display: block;
    }

    .dealHeader_input {
        display: block;
    }

    .refreshIcon {
        display: inline-block;
    }

    .export {
        display: inline-block;
        margin-top: 1rem;

        p {
            display: inline-block;
        }
    }

    .paperSelect {
        display: block;
        margin-bottom: .5rem;
    }

    .selectMarginAll {
        margin-bottom: .3rem;
    }
}


@media(max-width:400px) {
    .open {
        width: 100%;
    }

    .date_select {
        display: block;
    }

    .selectDashboard {
        margin-left: -3%;
    }

    .date_range {
        margin-top: .5rem;
        width: 15rem;
    }
}