@import "../../_Variable";

.main-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: linear-gradient(to right, $header_bg1, $header_bg2);
  background: #191B1A;
  padding: 0.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-right: 20px;
}

.icon {
  display: none;
  color: #fff;
}

.logo {
  flex: 1;
  color: $headerfont_color;
}

h3 {
  color: $headerfont_color ;
  font-size: 20px;
  font-size: CircularStd, sans-serif;
  font-weight: 400;
  flex: 3;
}

.menu-icon {
  flex: 0.3;
  color: $headerfont_color ;
}

.top-nav-search,
.language-main,
.notification-dropdown,
.message-dropdown {
  margin: 0 0.5rem;
  background-color: #FFFFFF26;
}

.nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  .nav-item {
    margin: 0 1rem;
  }
}

.top-nav-search {
  display: flex;
  border-radius: 10px;
  color: #fff;

  .search-input {
    border: 0px solid;
    padding: 0.5rem;
    color: $headerfont_color;
    background-color: transparent;
    width: 130px;
    height: 30px;
  }

  .btn {
    border: 0px solid;
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding: 0.5rem;
    background-color: #FFFFFF26;
    background-color: transparent;
    color: $headerfont_color;
    cursor: pointer;

    // &:hover {
    //   // background-color: #0056b3;
    // }
  }
}

.language-main {
  position: relative;
  height: 40px;
  align-content: center;
  border-radius: 10px;
  border: 0px;
  // color: #fff;

  .MuiSelect-root,
  .MuiMenuItem-root {
    display: flex;
    align-items: center;
    min-width: 20px;
    border-radius: 10px;
    height: 40px;
    color: #fff;
    font-size: small;
  }

  .MuiListItemIcon-root {
    min-width: 8px;
  }
}

.notification-dropdown,
.message-dropdown {
  position: relative;
  height: 40px;
  align-content: center;
  width: 35px;
  border-radius: 7px;

  .notification-icon,
  .message-icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 5px;
    color: #fff;
    width: 25px;
    height: 20px;
    align-self: center;

    .notification-count,
    .msg-count {
      position: absolute;
      top: -15px;
      right: -13px;
      background-color: #9368E9;
      color: #fff;
      border-radius: 50%;
      padding: 0 5px;
      font-size: 10px;
      width: 5px;
      height: 13px;
    }
  }
}

.notification-list,
.msg-list {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 4px;
  z-index: 1000;

  .heading,
  .heading1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e0e0e0;

    a {
      color: red;
      text-decoration: none;
      font-size: small;
      font-weight: 400;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #e0e0e0;
      background-color: #FFFFFF26;
      border-radius: 10px;

      &:hover {
        background-color: #f5f5f5;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        width: 100%;

        img {
          border-radius: 50%;
          margin-right: 0.5rem;
          width: 40px;
          height: 40px;
        }

        .notification-details,
        .msg-details {
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
          }
        }
      }
    }
  }
}

// }

.notification-dropdown {
  .notification-list {
    top: 3rem;
    right: 0;
  }
}

.message-dropdown {
  .msg-list {
    top: 3rem;
    right: 0;
  }
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  // margin-right: 12px;

  .active {
    position: relative;
  }

  .active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(10%);
    width: 10px;
    height: 10px;
    border: #f0f0f0;
    background-color: $active_status;
    border-radius: 50%;
  }

  .inactive-avatar {
    border: 2px solid red;
  }

  .admin-dropdown {
    position: relative;
    right: 0;
  }

  .admin-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .admin-dropdown-menu-item {
    padding: 10px;
    cursor: pointer;

    h3 {
      color: $headerfont_color;
      font-size: 50px;
      font-family: CircularStd, sans-serif;
      font-weight: 100;
      flex: 3;

    }
  }
}

.admin-lebal {
  color: $headerfont_color;
  text-transform: capitalize;
  font-size: medium;
  font-weight: 500;
  margin-right: 0px;

}

.admin-arrow {
  color: $headerfont_color ;

}

@media (max-width: 990px) and (min-width: 576px) {
  .main-div {
    display: flex;
    height: 50px;
    // background: linear-gradient(to right, $header_bg1, $header_bg2);
    background: #191B1A;
    padding-right: 0px;
  }

  .icon {
    display: block;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    margin-left: 0;
    font-weight: 500;
    font-size: medium;
  }

  .menu-icon,
  .search-input,
  h3,
  .admin-lebal,
  .admin-arrow {
    display: none;
  }

  .avatrfullcsreen {
    display: none;
  }
}

@media (max-width: 575px) {
  .main-div {
    display: flex;
    height: 50px;
    // background: linear-gradient(to right, $header_bg1, $header_bg2);
    background: #191B1A;
    padding-right: 0;
  }

  .icon {
    display: block;
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-left: 50px;
    font-weight: 900;
    font-size: medium;
  }

  .menu-icon {
    display: none;
  }

  .avtar {
    display: none;
  }

  .nav {
    list-style-type: none;
    display: flex;
    align-items: center;

    .top-nav-search {
      display: none;
    }
  }

  .language-dropdown {
    display: none;
  }

  .notification-dropdown {
    display: none;
  }

  .message-dropdown {
    display: none;
  }

  .menu-icon {
    float: left;
  }

  h3 {
    display: none;
  }

  .language-main {
    display: none;
  }
}