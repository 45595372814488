@import "/src/variable";
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: $mainbackground_color;
  background-image: url(../../Images/firstbackimage.png);
  background-position: left bottom;
  background-size: cover;
  background-repeat: no-repeat;

  .box_container {
    display: flex;
    justify-content: center;
    width: 70%;
    background: rgb(25, 27, 26);
    background: linear-gradient(20deg, #fff 0%, #d3f16e98 100%);
    border: 2px solid #d3f85a09;
    border-radius: 20px;
    box-shadow: 5px 5px 5px 0px $box_shadow;
    filter: drop-shadow(5px 5px 5px $boxshadow_filter);
    transition: 1s;

    &:hover {
      transform: translatey(-10px);
      box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    }
  }

  .loginBody {
    height: 50%;
    width: 40%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    gap: 25px;
    padding: 50px;

    h1 {
      font-size: 3rem;
    }
    .email_textbox {
      width: 100%;
      border: 2px solid rgb(136, 177, 224);
      border-radius: 10px;
      input {
        color: $input_text;
        font-weight: 500;
      }
    }
    .password_textbox {
      width: 100%;
      border: 2px solid rgb(136, 177, 224);
      border-radius: 10px;
      input {
        color: $input_text;
        font-weight: 500;
      }
      &:focus {
        border: none;
      }
    }
    .login_button {
      width: 30%;
      height: 5vh;
      border-radius: 30px;
      background-color: $button_bg;
      border: 2px solid #1d22204f;
      font-weight: 600;
      font-size: 1.2rem;
      color: $input_text;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .login {
    overflow-x: hidden;
    .box_container {
      width: 70%;
      height: calc(100vh - 15.7vh);

      .loginBody {
        margin-top: 70px;
      }
      .Login_cartoon {
        margin-top: -65px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .login {
    overflow-x: hidden;
    .box_container {
      width: 90%;
      height: calc(100vh - 17vh);

      .loginBody {
        margin-top: 80px;
        gap: 20px;
        padding: 40px;
        margin-left: 30px;

        h1 {
          font-size: 2.5rem;
        }
        .email_textbox {
          width: 100%;
        }
        .password_textbox {
          width: 100%;
        }
        .login_button {
          width: 45%;
          height: 7.5vh;
        }
      }
      .Login_cartoon {
        margin-top: -50px;
        width: 80%;
        margin-left: 20px;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 769px) {
  .login {
    overflow-x: hidden;
    .box_container {
      width: 70%;
      height: calc(100vh - 17vh);
      display: flex;
      justify-content: center;

      .loginBody {
        margin-top: 50px;
        gap: 25px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        h1 {
          font-size: 2.5rem;
        }
        .email_textbox {
          width: 100%;
        }
        .password_textbox {
          width: 100%;
        }
        .login_button {
          width: 45%;
          height: 7.5vh;
        }
        .css-1nktc3y {
          width: 2% !important;
        }
      }
      .Login_cartoon {
        margin-left: 0px;
        display: none;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 481px) {
  .login {
    overflow-x: hidden;
    .box_container {
      width: 90%;
      height: calc(100vh - 30vh);
      display: flex;
      justify-content: center;

      .loginBody {
        margin-top: 60px;
        gap: 25px;

        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        h1 {
          font-size: 2.3rem;
        }
        .email_textbox {
          width: 100%;
        }
        .password_textbox {
          width: 100%;
        }
        .login_button {
          width: 45%;
          height: 7.5vh;
        }
        .css-1nktc3y {
          width: 2% !important;
        }
      }
      .Login_cartoon {
        margin-left: 0px;
        display: none;
      }
    }
  }
}
