@import "/src/variable";

* {
    margin: 0px;
    padding: 0px;
    //box-sizing: border-box;
}

.Empmain {
    width: calc(100% - 15rem);
    padding: 1.5rem;
    float: right;
    box-sizing: border-box;

}

.main {
    background-color: #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width:100%;
    //height:auto;
    margin: 00px 0px;
    justify-content: center;

}

.open {
    width: calc(100% - 5rem);
    float: right;
}

.Empsub {
    width: 29%;
    background-color: #b54f87;
}

.main>div {
    width: 33%;
    height: auto;
    background-color: #f7f7f7;
    justify-content: space-around;
    //margin:0px 5px;
    //padding:5px 5px;
}

.flexcontainer {
    display: flex;
    background: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    flex-wrap: wrap;
    border-radius: 5px;
    margin: 20px 20px;
    padding: 20px;
    height: auto;
    // width:65%;
    justify-content: space-around;

}

.flexcontainer>div {
    // background-color:blue;
    margin: 5px;
    padding: 0px;
    font-size: 18px;
}

.set {
    width: 70%;
}

.set1 {
    width: 30%;
}

//1st column 2nd box
.statistic {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    margin: 20px 20px;
    padding: 20px;
    height: auto;
    //width:65%;
    justify-content: space-around;

}

.statistic>div {
    color: black;
    margin: 10px 5px;
    padding: 10px 5px;

}

.stat {
    display: flex;
    flex-direction: row;
}

.stat>div {
    width: 100%;
}

.stat2 {
    background-color: rgb(232, 210, 214);
    display: flex;
    flex-direction: row;
    border-radius: 5px;
}

.stat2>div {
    width: 50%;
    height: 50px;
}

.stat3 {
    background-color: white;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    justify-content: space-between;
}

.stat3>div {
    margin: 3px 3px;
    padding: 1px 1px;
    width: 40%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
}

.today {
    width: 150px;
    height: 0px;
}

//1st column 3rd box
.holiday {
    display: flex;
    flex-direction: column;
    background: #009efb;
    border-radius: 5px;
    margin: 20px 20px;
    padding: 20px;
    height: auto;
    //width:65%;
    justify-content: space-around;
}

.holiday>div {
    // margin:3px 3px;
    padding: 3px 3px;
    //width:100%;
    // height:50px;
}

.holiday1 {
    display: flex;
    flex-direction: row;

}

.holiday1>div {
    margin: 0px 0px;
    padding: 3px 3px;
    width: auto;
    height: 50px;
}

.newbtnn 
{
    width: 100px;
    height: 35px;
    background-color: azure;
}

.newbtnn:hover {
    background-color: #f7f7f7;
}

//2nd column 1st
.box1 {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    margin: 20px 20px;
    padding: 20px;
    height: auto;
    // width:70%;
    justify-content: space-around;


}

.box1>div {
    width: 100%;
    height: auto;
    //background-color: blue;
    margin: 10px 10px;

}

.AL {

    width: 80%;
    margin-top: 8px;
    font-weight: 500;
    font-size: 20px;
}

.AL2 {
    margin-left: 30px;
}

.chart {
    margin-left: 0px;
}

//3rd column 

.largebox {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    margin: 20px 20px;
    padding: 20px;
    height: auto;
    width: 80%;
    justify-content: space-around;

    .view:hover {
        color: darkorange;
        cursor: pointer;
    }

    .longbox {
        display: flex;
        width:auto;
        height: 4.5rem;
        background-color: white;
        margin-top: 20px;
        font-size: 13.5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        

    }

}

// square-box
.newdiv {
    background-color: #f7f7f7;
    width: calc(100% - 13rem);
    padding: 1.5rem;
    float: right;
    box-sizing: border-box;
}

.newflexcon {
    display: flex;
    //flex-wrap: wrap;
    background-color: white;
    border-radius: 5px;
    margin: 0px 50px;
    padding: 20px;
    height: 380px;
    //width: 92%;
    justify-content: space-around;

}

.newflexcon>div {

   // flex-wrap: wrap;
    background-color: white;
    margin: 10px;
    padding: 10px;
    font-size: 15px;
    width: 60%;
    height: 330px;
    border-style: groove gray;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: black;
}

.header {
    background-color: rgba(243, 197, 204, 0.7);
    margin-left: 40px;
    margin-top: 10px;
    border-radius: 5px;
    width: fit-content;
    font-size: 15px;
}

.graybox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: rgb(230, 227, 227);
    color: black;
    height: 60px;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    width: 90%
}

.pleader {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: black;
    // margin-top:20px;
}

.lastflex {
    display: flex;
    //flex-wrap: wrap;
    //background-color: #f7f7f7;
    border-radius: 5px;
    margin: 0px 45px;
    padding: 20px;
    height: 300px;
    width: 92%;
    justify-content: space-between;
}

.lastflex>div {
    height: auto;
    border-color: #b54f87;
    margin: 0px 20px;
    padding: 40px 40px;
    width: 50%;
    height: auto;
    border-radius: 5px;
    border-style: groove;
    border-color: #ff0016;
}

.pinkbox {
    background-color: rgb(221, 205, 207);
    display: flex;
    flex-direction: row;
}

.pinkbox>div {
    //width:100%;
    //background-color: #f7f7f7;
    height: 200px;
}

.text {
    font-size: 22px;
    font-weight: 500;
    color: rgb(47, 46, 46);
}

.mainbox>.div {
    width: 50%;
}

.divtext {
    width: 70%;
}

.divimg {
    width: 30%;
    margin-left: 20px;
}

.innerbox {
    display: flex;
    flex-direction: row;
    width: 100%;
    //background-color: #009efb;
    margin-top: 15px;
}

.innerbox>div {
    width: 70%;
    background-color: rgb(195, 219, 195);
    border-radius: 5px;
    height: 130px;
    margin: 3px 10px;
    padding: 5px 5px;

}

.HR {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.cmp {
    background-color: white;
    //display: flex;
    flex-wrap: wrap;
}
@media only screen and (max-width: 990px)
{
    .leaveempMain_Container {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }


}
@media only screen and (max-width: 990px)
{
    .Empmain {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }
    .main {
        display: flex;
        width: 100%;
        height: fit-content;
    }

    .main>div {
        display: inline-block;
        width: 33%;
    }
    .flexcontainer {
        display: block;
        width: 100%;

        .flexcontainer>div {
            width: 100%;
            display: block;
        }
    }

    .statistic {
        display: block;
        width: 100%;

    }

    .holiday {
        display: block;
        width: 100%;
    }

    .chart {
        display: block;
        width: 100%;
        justify-content: center;
    }

    .longbox {
        display:block;
        width: 15rem;
        //height:fit-content;
    }
    .newdiv {
        display: block;
        background-color: #f7f7f7;
        width: calc(100%);
        padding: 1.5rem;
        float: right;
        box-sizing: border-box;
    }

    .newflexcon {
        display: block;
        width:fit-content;
        height: auto;

    }

    .newflexcon>div {
        display: block;
        height: auto;
        width: fit-content;

    }

    .lastflex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: fit-content;
        height: auto;

    }

    .lastflex>div {
        display: flex;
        width:100%;
    }

    .pinkbox {
        display: block;
        //width:fit-content;
    }

    .pinkbox>div {
        display: block;
        //width: fit-content;
        height: auto;
        justify-content: center;
    }

    .cmp {
        display: block;
        margin-left: 10px;
        width: 100%;
        height: auto;
    }

    .innerbox {
        display: flex;
        //margin-left:30%;

    }

    .innerbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .HR {
        width: 100%;
        height: auto;
        justify-content: center;
    }

}
@media only screen and (max-width: 850px)
{
    .Empmain {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }
    .main {
        display: block;
        width: 100%;
        height: fit-content;
    }

    .main>div {
        display: inline-block;
        width: 100%;
    }
    .flexcontainer {
        display: block;
        width: 94%;

        .flexcontainer>div {
            width: 100%;
            display: block;
        }
    }

    .statistic {
        display: block;
        width: 100%;

    }

    .holiday {
        display: block;
        width: 100%;
    }

    .chart {
        display: block;
        width: 100%;
        justify-content: center;
    }
    .largebox
    {
        width:94%;
    }

    .longbox {
        margin-left: 21%;
        display:block;
        width: 20rem;
        height:5rem;
    }
    
    .newflexcon {
        display: block;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0px 0px;

    }

    .newflexcon>div {
        display: block;
        height: auto;

    }

    .lastflex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: fit-content;
        height: auto;

    }

    .lastflex>div {
        display: flex;
        width:100%;
    }

    .pinkbox {
        display: block;
        //width:fit-content;
    }

    .pinkbox>div {
        display: block;
        //width: fit-content;
        height: auto;
        justify-content: center;
    }

    .cmp {
        display: block;
        margin-left: 10px;
        width: 100%;
        height: auto;
    }

    .innerbox {
        display: flex;
       // margin-left:30%;

    }

    .innerbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .HR {
        width: 100%;
        height: auto;
        justify-content: center;
    }

}


@media only screen and (max-width: 600px) {
    .Empmain {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }
    .main {
        display: block;
        width: 100%;
        height: fit-content;
    }

    .main>div {
        display: inline-block;
        width: fit-content;
    }
    .flexcontainer {
        display: block;
        width: 100%;

        .flexcontainer>div {
            width: 100%;
            display: block;
        }
    }

    .statistic {
        display: block;
        width: 100%;

    }

    .holiday {
        display: block;
        width: 100%;
    }

    .chart {
        display: block;
        width: 100%;
        justify-content: center;
    }
    .largebox
    {
        width:100%;
    }

    .longbox {
       margin: 0px 0px;
        display:block;
        width: 20rem;
        height:5rem;
    }
    .newflexcon {
        display: block;
        flex-wrap: wrap;
        width: 100%;
        height: auto;

    }

    .newflexcon>div {
        display: block;
        height: auto;

    }

    .lastflex {
        display: block;
        flex-wrap: wrap;
        width: fit-content;

    }

    .lastflex>div {
        display: block;
        flex-wrap: wrap;
        height: auto;
        width:100%;
    }

    .pinkbox {
        display: block;
    }

    .pinkbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .cmp {
        display: block;
        margin-left: 10px;
        width: 100%;
        height: auto;
    }

    .innerbox {
        display: block;

    }

    .innerbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .HR {
        width: 100%;
        height: auto;
        justify-content: center;
    }

}


@media only screen and (max-width: 460px) {
    .Empmain {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }
    
    .main {
        display: block;
        width: fit-content;
    }

    .main>div {
        display: inline-block;
        width: fit-content;
    }

    .flexcontainer {
        display: block;
        width: 100%;

        .flexcontainer>div {
            width: 100%;
            display: block;
        }
    }

    .statistic {
        display: block;
        width: 100%;

    }

    .holiday {
        display: block;
        width: 100%;
    }

    .chart {
        display: block;
        width: 100%;
        justify-content: center;
    }

    .longbox {
        display: inline-block;
        width: 100%;
        height: 500px;
    }

    .newdiv {
        // display: block;
        // background-color: #f7f7f7;
        // width:100%;
        // padding: 0.5rem;
        // float: right;
        // box-sizing: border-box;
        // margin-left:10%;

        width: calc(100% - 5rem);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }

    .newflexcon {
        display: block;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
    }

    .newflexcon>div {
        display: block;
        height: auto;
        width: fit-content;
        color: black;

    }

    .lastflex {
        display: block;
        flex-wrap: wrap;
        width: fit-content;

    }

    .lastflex>div {
        display: block;
        flex-wrap: wrap;
        height: auto;
        width: fit-content;
    }

    .pinkbox {
        display: block;
    }

    .pinkbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .cmp {
        display: block;
        margin-left: 10px;
        width: 100%;
        height: auto;
    }

    .innerbox {
        display: block;

    }

    .innerbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .HR {
        width: 100%;
        height: auto;
        justify-content: center;
    }


}

@media only screen and (max-width: 320px) {
    .stat3 {
        display: block;
        width: 100%;
        justify-content: center;
    }

    .stat3>div {
        margin-left: 30%;
        padding: auto;
    }

    .longbox {
        display: block;
        width: 100%;
        height: 50%;
    }
    .Empsub
    {
        width:100%;
    }

    .newdiv
    {
        display: ruby;
        width: calc(100% - 2rem);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
        margin: 0px 0px;

   
    }

    .newflexcon {
        display: block;
        width: 100%;
        height: fit-content;
       
    }

    .newflexcon>div {
        display: block;
        // height: auto;
        // width: fit-content;
        // color: black;

    }

    .lastflex {
        display: block;
        flex-wrap: wrap;
        width: fit-content;

    }

    .lastflex>div {
        display: block;
        flex-wrap: wrap;
        height: auto;
        width: fit-content;
    }

    .pinkbox {
        display: block;
    }

    .pinkbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .cmp {
        display: block;
        margin-left: 10px;
        width: 100%;
        height: auto;
    }

    .innerbox {
        display: block;

    }

    .innerbox>div {
        display: block;
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .HR {
        width: 100%;
        height: auto;
        justify-content: center;
    }



}