@import "/src/variable";

* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.open {
    width: calc(100% - 5rem);
    float: right;
    padding: 1.5rem;
    transition: all 0.2s;
    background-color: $dashboard_bg;
}

.adminLeaveMain_Container {
    width: calc(100% - 15rem);
    padding: 1.5rem;
    float: right;
    transition: all 0.2s;
    background-color: $dashboard_bg;
}

.alHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.headingSubText {
    display: flex;

    a {
        text-decoration: none;
        color: $input_text;
        font-weight: 500;
    }
}

.hadingBtn {
    min-width: 9rem;
    border-radius: 3rem;
    height: 2.5rem;
    color: $headerfont_color;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: $dashboard_icon;
    border: 1px solid $dashboard_icon;
}

.leaveType {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1.2rem;

    h6 {
        text-align: center;
        font-weight: bold;

        span {
            color: $sidebar_subfont;
            font-size: medium;
            font-weight: 600;
        }
    }

    p {
        font-weight: 500;
        text-align: center;
    }
}

.diff_leaveType {
    border: 1px solid $sidebar_mainfont;
    width: 24%;
    padding: .5rem 1rem;
    border-radius: .5rem;
    background-color: $dashboard_box;
}

.leaveData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.leaveDataElem {
    width: 15.5%;
    position: relative;
    background-color: $dashboard_box;
}

.dateIcon {
    position: absolute;
    right: 0rem;
    bottom: 1.2rem;
}

.rangeate {
    width: 5rem;
}

.searchButton {
    background-color: $active_status;
    font-size: 1.2rem;
    color: $dashboard_box;
    border-radius: .3rem;
    width: 100%;
    height: 3.5rem;
    border: 1px solid $active_status;
}

.tableHeader {
    display: flex;

    p {
        color: $sidebar_subfont;
        font-weight: 600;
    }
}

.new {
    color: $new_status;
}

.approved {
    color: $active_status;
}

.panding {
    color: $panding_status;
}

.declined {
    color: $inactive_status;
}

// adminleavetable.tsx
.tableHeading {
    background-color: $headerfont_color;
}

.popupBtn {
    border: 0;
    height: 2rem;
    width: 7rem;
    display: flex;
    align-items: center;
}

.usepagination {
    // .MuiPaginationItem-root {
    //     &:hover {
    //         color:$sidebar_subfont !important ;
    //     }

    //     .Mui-selected {
    //         background-color: $dashboard_icon !important;
    //         color: $dashboard_box !important;
    //     }
    // }  
}

//dialog box
.dialogHeader {
    display: flex;
    justify-content: space-between;
}

.dateicondg {
    position: relative;
    left: 25rem;
    bottom: 3rem;
}

.disableInput {
    background-color: $sidebar_mainfont;
}

.dialogBoxBtn {
    padding: .5rem 4rem !important;
    margin: 1.5rem !important;
    border-radius: 1rem !important;
    color: $dashboard_box !important;
    background-color: $dashboard_icon !important;

    &:hover {
        background-color: $dashboard_icon !important;
        transition: all .2s ease;
    }
}

.dgwidth {
    .MuiDialog-container .MuiPaper-root {
        width: 100%;
        max-width: 500px;
    }
}


@media(max-width:990px) {
    .open {
        width: 100%;
        padding: 1.5rem;
    }

    .adminLeaveMain_Container {
        width: 100%;
    }

    .leaveData {
        flex-wrap: wrap;
    }

    .leaveDataElem {
        margin: .2rem;
        width: 31%;
    }
}

@media(max-width:767px) {
    .leaveType {
        display: block;
    }

    .diff_leaveType {
        width: 97%;
        margin: .7rem;
    }

    .leaveData {
        padding: .5rem;
    }

    .leaveDataElem {
        width: 48%;
    }

}

@media(max-width:575px) {
    .headingSubText {
        display: none;
    }

    .leaveType {
        margin-bottom: 0%
    }

    .leaveDataElem {
        width: 100%;
    }
}