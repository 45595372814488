@import "/src/variable";

.employeesMain_Container {
  width: calc(100% - 15rem);
  padding: 1.5rem;
  float: right;
  box-sizing: border-box;
  background-color: $dashboard_bg;
  height: 100vh;
}
.open {
  width: calc(100% - 5rem);
  float: right;
  background-color: $dashboard_bg;
  height: 100vh;
}
.Employee_topnav {
  display: flex;
  align-items: center;

  .top_text {
    width: 80%;
    margin: 0.2rem;

    h2 {
      font-size: 26px;
      font-weight: 500;
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      color: $dashboard_deamcolor;
    }
  }
  .top_buttons {
    display: flex;
    align-items: center;
    width: 20%;
    gap: 5%;

    img {
      width: 20%;
      height: 4vh;
    }
    button {
      width: 100%;
      height: 5vh;
      background-color: $dashboard_icon;
      font-size: 16px;
      font-weight: 600;
      border-radius: 30px;
    }
    .name_row {
      margin: 30px;
      background-color: aquamarine;
    }
  }
}

.search_row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .search_box {
    padding: 0;
    box-shadow: none;

    .search_down {
      width: 390px;

      .employee_designation {
        width: 100%;
      }
    }

    .employee_id {
      width: 100%;
      border: none !important;

      input {
        background-color: $dashboard_box;
        border-radius: 10px;

        &:focus {
          border: 2px solid $leave_line;
          border-bottom: none;
          color: $dashboard_font;
        }
      }
    }
    .Search_btn {
      width: 100%;
      height: 5.6vh;
      background-color: $client_search_btn;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.Employee_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .boxrow_2 {
    margin-top: 20px;
  }
  .person_box {
    height: 20vh;

    .more_icon {
      float: right;
      width: 2%;
    }

    .option_box {
      .Editicon_img {
        width: 5%;
      }
      // .Item_edit {
      // }
    }
    .employe_Details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      img {
        width: 25%;
        height: 10vh;
        border-radius: 60px;
      }
      h3 {
        color: $dashboard_font;
        font-size: 1.3rem;
      }
      p {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .Employee_topnav {
    .top_text {
      width: 70%;
    }
    .top_buttons {
      width: 25%;
      gap: 4%;
      img {
        width: 20%;
        height: 6vh;
      }
      button {
        width: 100%;
        height: 6vh;
      }
    }
  }

  .search_row {
    .search_box {
      height: 10vh;

      .search_down {
        width: 330px;

        .employee_designation {
          width: 100%;
          height: calc(100vh - 97vh);
        }
      }

      .employee_id {
        width: 100%;

        input {
          height: calc(100vh - 97vh);
        }
      }
      .Search_btn {
        width: 100%;
        height: 7vh;
      }
    }
  }

  .Employee_list {
    .person_box {
      height: 25vh;

      .option_box {
        .Editicon_img {
          width: 5%;
        }
      }
      .employe_Details {
        img {
          width: 25%;
          height: 11vh;
          border-radius: 60px;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .Employee_topnav {
    .top_text {
      width: 70%;
    }
    .top_buttons {
      width: 25%;
      gap: 4%;
      img {
        width: 15%;
        height: 4vh;
      }
      button {
        width: 100%;
        height: 6vh;

        font-size: 16px;
      }
    }
  }

  .search_row {
    .search_box {
      height: 10vh;

      .search_down {
        width: calc(100vh - 65vh);
      }

      .employee_id {
        width: 100%;
      }
      .Search_btn {
        width: 100%;
        height: 7vh;
      }
    }
  }

  .Employee_list {
    margin-top: 20px;

    .person_box {
      height: 25vh;

      .option_box {
        .Editicon_img {
          width: 5%;
        }
      }
      .employe_Details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        img {
          width: 27%;
          height: 10vh;
          border-radius: 60px;
        }
        h3 {
          color: $dashboard_font;
          font-size: 1.3rem;
        }
        p {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .Employee_topnav {
    .top_text {
      width: 69%;
    }
    .top_buttons {
      width: 25%;
      gap: 4%;
      img {
        width: 15%;
        height: 4vh;
      }
      button {
        width: 100%;
        height: 6vh;

        font-size: 14px;
      }
    }
  }

  .search_row {
    .search_box {
      height: 10vh;

      .search_down {
        width: calc(100vh - 70vh);
      }

      .employee_id {
        width: 100%;
      }
      .Search_btn {
        width: 100%;
        height: 7vh;
      }
    }
  }

  .Employee_list {
    margin-top: 20px;

    .person_box {
      height: 25vh;

      .option_box {
        .Editicon_img {
          width: 5%;
        }
      }
      .employe_Details {
        img {
          width: 33%;

          height: calc(100vh - 88vh);
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  .Employee_topnav {
    .top_text {
      width: 60%;
    }
    .top_buttons {
      width: 33%;
      gap: 4%;
      img {
        width: 15%;
        height: 4vh;
      }
      button {
        width: 100%;
        height: 6vh;

        font-size: 14px;
      }
    }
  }

  .search_row {
    display: flex;

    .search_box {
      height: 10vh;

      .search_down {
        width: calc(100vh - 77vh);
      }

      .employee_id {
        width: 100%;
      }
      .Search_btn {
        width: 100%;
        height: 7vh;
      }
    }
  }

  .Employee_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .boxrow_2 {
      margin-top: 5px;
      display: grid;
      grid-template-columns: auto auto;
    }
    .person_box {
      height: 30vh;
      width: 400% !important;

      .option_box {
        .Editicon_img {
          width: 5%;
        }
      }

      .employe_Details {
        text-align: center;

        img {
          width: 33%;
          height: calc(100vh - 84vh);
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .Employee_topnav {
    .top_text {
      width: 40%;

      h2 {
        font-size: 22px;
      }
      h3 {
        font-size: 16px;
      }
    }
    .top_buttons {
      width: 50%;
      gap: 4%;
      img {
        width: 15%;
        height: 3vh;
      }
      button {
        width: 95%;
        height: 5vh;
        font-size: 14px;
      }
    }
  }

  .search_row {
    display: flex;

    .search_line {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
    }

    .search_box {
      height: 10vh;
      width: 400% !important;

      .search_down {
        width: calc(100vh - 77vh);
      }

      .employee_id {
        width: 100%;
      }
      .Search_btn {
        width: 100%;
        height: 7vh;
      }
    }
  }

  .Employee_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .boxrow_2 {
      margin-top: 5px;
      display: grid;
      grid-template-columns: auto auto;
    }
    .person_box {
      height: 30vh;
      width: 400% !important;

      .option_box {
        .Editicon_img {
          width: 5%;
        }
      }

      .employe_Details {
        text-align: center;

        img {
          width: 60%;

          height: calc(100vh - 84vh);
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  body {
    max-width: 100%;
    overflow-x: hidden;

    .Employee_topnav {
      .top_text {
        width: 25%;
        margin: 1.5rem;

        h2 {
          font-size: 22px;
        }
        h3 {
          font-size: 16px;
          display: none;
        }
      }
      .top_buttons {
        width: 63%;
        gap: 3%;
        img {
          width: 15%;
          height: 3.5vh;
        }
        button {
          width: 100%;
          height: 5vh;

          font-size: 12px;
        }
      }
    }

    .search_row {
      display: flex;

      margin-top: 15px;

      .search_line {
        display: inline-block;

        justify-content: center;
      }

      .search_box {
        height: 8vh;
        padding: 15px;

        width: 400% !important;

        .search_down {
          width: calc(100vh - 55vh);
        }

        .employee_id {
          width: 100%;
        }
        .Search_btn {
          width: 100%;
          height: 7vh;
        }
      }
    }

    .Employee_list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .boxrow_2 {
        margin-top: 5px;
        display: inline-block;
      }
      .person_box {
        height: 30vh;
        width: 400% !important;

        .option_box {
          .Editicon_img {
            width: 5%;
          }
        }

        .employe_Details {
          text-align: center;

          img {
            width: 35%;

            height: calc(100vh - 85vh);
          }
        }
      }
    }
  }
}
