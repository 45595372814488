body
{
    background-color: #f2efef;
}

.leaveempMain_Container {
    width: calc(100% - 15rem);
    padding: 1rem;
    float: right;
    box-sizing: border-box;
}

.open {
    width: calc(100% - 5rem);
    float: right;
    margin-top: 15px;
}
.leavediv
{
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    height:70px;
    justify-content: space-between;
    //background-color: blue;
}
.leavetxt
{
    font-size:30px;
    font-weight: 600;
}
.innerleavediv
{
    width:30%;
}
.addbtn
{
    margin-top:10px;
}
.row
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 130px;
    //background-color: blue;
    justify-content: space-around;
}
.row>div
{
    background-color: white;
    width:22%;
    height:70px;
    margin: 10px 0px;
    padding: 10px 10px;
}
.statsinfo
{
    display: block;
    text-align: center;
    padding: 10px 10px;
    background-color: #fff;
    border: 1px solid #e9e9ea;
    border-radius: 4px;
    font-size:25px;
    font-weight: 200;
    height: 73px;
}
.tablemain
{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    width:100%;
    border-radius: 5px;
    //background-color: blue;
}
.tablemain>div
{
    //background-color: white;
    height:30px;
    width:97%;
    margin:10px 10px;
    padding:10px 10px;
}
.showentries
{
    display: flex;
    flex-direction: row;
    width:100%;
}
.new
{
    color:violet;
}
.panding
{
    color:blue;
}
.approved
{
    color:green;
}
.declined
{
    color:red;
}
.tableHeader
{
    display: flex;
}
@media only screen and (max-width: 990px)
{
    .leaveempMain_Container {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }


}
@media only screen and (max-width: 550px)
{
    *
    {
        margin: 0px;
        padding: 1px;
    }
    .leaveempMain_Container {
        width: calc(100%);
        padding: 1rem;
        float: right;
        box-sizing: border-box;
    }
    
    .open {
        width: calc(100% - 17rem);
        float: right;
        margin-top: 15px;
    }
    .leavediv
{
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    height:70px;
    justify-content: space-between;
    //background-color: blue;
}
.row
{
    display: block;
    //flex-wrap: wrap;
    width: 100%;
    height: auto;
    //background-color: blue;
    justify-content: center;
}
.row>div
{
    display: block;
    width: 80%;
    height: 73px;
    margin:10px 37px;
    
}
}