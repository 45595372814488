.open {
    width: calc(100% - 5rem);
    float: right;
    margin-top: 20px;
}
.overtimemain {
    width: calc(100% - 15rem);
    padding: 1rem;
    float: right;
    box-sizing: border-box;
    }
.leavediv
{
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    height:70px;
    justify-content: space-between;
    //background-color: blue;
}
.leavetxt
{
    font-size:30px;
    font-weight: 600;
}
.innerleavediv
{
    width:30%;
}
.addbtn
{
    margin-top:10px;
}
.row
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 130px;
    //background-color: blue;
    justify-content: space-around;
}
.row>div
{
    background-color: white;
    width:22%;
    height:80px;
    margin: 10px 0px;
    padding: 10px 10px;
}
.statsinfo
{
    display: block;
    text-align: center;
    padding: 10px 10px;
    background-color: #fff;
    border: 1px solid #e9e9ea;
    border-radius: 4px;
    font-size:25px;
    font-weight: 200;
    height: 73px;
}
.new
{
    color:violet;
}
.panding
{
    color:blue;
}
.approved
{
    color:green;
}
.declined
{
    color:red;
}
.tableHeader
{
    display: flex;
}
.popupBtn
{
    width:80px;
}